<template>
  <validation-observer ref="masterPasswordForm">
    <b-col
      class="p-0"
      md="4"
    >
      <app-overlay>
        <b-card header-class="justify-content-between align-items-center py-1">
          <template #header>
            <h3 class="m-0">
              Master Şifre
            </h3>
            <b-form-checkbox
              v-model="isActive"
              class="custom-control-success"
              switch
              @change="updateMasterPasswordIsActive"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </template>
          <hr class="mt-25">
          <app-input
            v-model="password"
            name="Şifre"
            type="password"
            label="Şifre:"
            placeholder="Şifre Giriniz..."
            rules="required|min:6"
            @keyup.enter.native="updateMasterPasswordSettings"
          />
          <app-button
            text="Güncelle"
            block
            @click="updateMasterPasswordSettings"
          />
        </b-card>
      </app-overlay>
    </b-col>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  components: { ValidationObserver },
  data() {
    return {
      _id: null,
      isActive: false,
      password: null,
    }
  },
  mounted() {
    this.getMasterPasswordSettings()
  },
  methods: {
    getMasterPasswordSettings() {
      this.$axios
        .get('/describings', { params: { key: 'masterPassword' }, loading: 'table' })
        .then(({ data }) => {
          this._id = data._id
          this.isActive = data.data.isActive
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateMasterPasswordIsActive() {
      this.$axios
        .patch(`/describings/${this?._id}`, { 'data.isActive': this.isActive }, { loading: 'table' })
        .then(({ data: { data } }) => {
          this.isActive = data.isActive
          this.$emitter.$emit('Notification', {
            variant: 'success',
            title: 'Aktiflik Güncellendi',
          })
        })
        .catch(err => {
          this.$emitter.$emit('Notification', {
            variant: 'danger',
            title: 'Hata oluştu.',
            message: err?.data?.message ?? null,
          })
          console.log(err)
        })
    },
    updateMasterPasswordSettings() {
      this.$validate(this.$refs.masterPasswordForm, () => {
        this.$axios
          .patch('/describings/update-master-password', { password: this.password }, { loading: 'table' })
          .then(() => {
            this.password = null
            this.$refs.masterPasswordForm.reset()
            this.$emitter.$emit('Notification', {
              variant: 'success',
              title: 'Şifre Güncellendi',
            })
          })
          .catch(err => {
            this.$emitter.$emit('Notification', {
              variant: 'danger',
              title: 'Hata oluştu.',
            })
            console.log(err)
          })
      })
    },
  },
}
</script>

<style></style>
