<template>
  <b-custom-tabs :tabs="tabs" />
</template>

<script>
import MasterPassword from "./MasterPassword.vue";

export default {
  data() {
    return {
      tabs: [{ title: "Master Şifre", component: MasterPassword }],
    };
  },
};
</script>

<style></style>
